import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckoutDeliveryAddressFacade, CheckoutStep, CheckoutStepType } from '@spartacus/checkout/base/root';
import { Address, getLastValueSync, GlobalMessageService, GlobalMessageType, RoutingService, TranslationService, UserAddressService, WindowRef } from '@spartacus/core';
import { Card, LaunchDialogService, getAddressNumbers, ICON_TYPE } from '@spartacus/storefront';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subscription, timer } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, switchMap, take, tap } from 'rxjs/operators';
import { CheckoutStepService } from "@spartacus/checkout/base/components";
import { CheckoutPaymentTypeFacade } from "@spartacus/checkout/b2b/root";
import { FormControl, FormGroup, UntypedFormGroup } from "@angular/forms";
import { CheckoutService, CheckPaymentResponse } from "../../../../../services/checkout.service";
import { Cart, OrderEntry } from "@spartacus/cart/base/root/models/cart.model";
import { CheckoutStepTypeExtended } from "../../../../../enums/checkout-step-type";
import { SubscriptionsService } from "../../../../../services/subscriptions.service";
import { FormConfig } from '../../../../../interfaces/address-details';
import { formConfig } from '../../../../configs/address-details-form-config';
import { PaymentType } from "../../../../../enums/payment-type.enum";
import { AddressValidationService } from '../../../../../services/address-validation.service';
import { User, UserAccountFacade } from "@spartacus/user/account/root";
import { Renewal, Role, SubscriptionProduct } from "../../../../../interfaces/subscription";
import { AddressType } from "../../../../../enums/address-type.enum";
import { CustomLoadingSpinnerService } from 'src/app/services/custom-loading-spinner.service';
import { CardWithAddress, ExtAddress, SubscriptionInformationItem, ValidationAddress } from 'src/app/interfaces/checkout';
import { FreightForwordFormComponent } from '../../../address-book/address-form/freight-forword-form/freight-forword-form.component';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { CartExtended, CartTypes, SingleConsignmentInfo } from '../../../../../interfaces/cart.d';
import { ViewportScroller } from "@angular/common";
import { GeneracActiveCartService } from '../../../cart/core/facade/active-cart.service';
import { CartType } from 'src/app/enums/cart-type.enum';
import { AllIcons, CustomIcon } from "../../../../../enums/custom-icon.enum";
import { GeneracAddressFormComponent } from "../../../address-book/address-form/address-form.component";

@Component({
  selector: 'cx-delivery-address',
  templateUrl: './custom-checkout-delivery-address.component.html',
  styleUrls: ['./custom-checkout-delivery-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCheckoutDeliveryAddressComponent implements OnInit, OnDestroy {
  @ViewChild('ffAddress') ffAddress: FreightForwordFormComponent;
  @ViewChild('dropShipForm') dropShipForm: GeneracAddressFormComponent;
  AddressType = AddressType;
  cart: CartExtended;
  cards$: Observable<CardWithAddress[]>;
  userAccount$: Observable<User> = this.userAccount.get();
  selectedCard: CardWithAddress;
  verifiedAddress: ExtAddress;
  isUpdating$: Observable<boolean>;
  formConfig: FormConfig[] = formConfig;
  shipOnceCheckbox = new FormControl(false);
  renewals: Renewal[] | null = null;
  roles: Role[] | null = null;
  doneAutoSelect = false;
  formGroup: FormGroup;
  isFormInitialized = false;
  fFAddresses: Array<ExtAddress> = [];
  fFAddressFormValue: Address;
  selectedFFDropdownValue: any;
  user: User;
  freightForwarderAddress: ExtAddress;
  floorPlanErrorMessage: string[];
  isSelling: boolean;
  isSubscription: boolean = false;
  isCartMixed: boolean = false;
  currentStep: CheckoutStep;
  iconTypes= {...ICON_TYPE, ...CustomIcon};
  internalCalculation: boolean;
  fieldToHighlight: string;
  saveDropShipAddress: boolean;
  limitCountOfAddressesMessage: string;
  subscriptionProducts: SubscriptionProduct[];
  checkoutInfo: any; 
  subscriptionInfo: any;
  protected busy$ = new BehaviorSubject<boolean>(false);
  protected continueDisabled$ = new BehaviorSubject<boolean>(false);
  private hasToSkipCart = false;
  private onChangeCurrentAddress$: Subscription;
  private subscriptions = new Subscription();
  protected singleConsignmentInfo: SingleConsignmentInfo;

  get isGuestCheckout(): boolean {
    return !!getLastValueSync(this.activeCartFacade.isGuestCart());
  }

  get backBtnText(): string {
    return this.checkoutStepService.getBackBntText(this.activatedRoute);
  }

  get selectedAddress$(): Observable<Address | undefined> {
    return this.checkoutDeliveryAddressFacade.getDeliveryAddressState().pipe(
        filter((state) => !state.loading),
        map((state) => {
          if(this.cart?.dropShipAddress) {
            this.verifiedAddress = state.data ? state.data : this.verifiedAddress;
          }
          return state.data}),
        distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
    );
  }

  constructor(
      protected userAddressService: UserAddressService,
      protected checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
      protected activatedRoute: ActivatedRoute,
      protected translationService: TranslationService,
      protected activeCartFacade: GeneracActiveCartService,
      protected checkoutStepService: CheckoutStepService,
      protected globalMessageService: GlobalMessageService,
      protected checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
      private checkoutService: CheckoutService,
      private subscriptionsService: SubscriptionsService,
      protected launchDialogService: LaunchDialogService,
      private validationService: AddressValidationService,
      private cdr: ChangeDetectorRef,
      private userAccount: UserAccountFacade,
      protected winRef: WindowRef,
      private routing: RoutingService,
      private router: Router,
      protected customLoadingSpinnerService: CustomLoadingSpinnerService,
      protected googleAnalyticsService: GoogleAnalyticsService,
      private viewportScroller: ViewportScroller,
  ) {
  }

  ngOnInit(): void {
    this.fieldToHighlight = this.activatedRoute.snapshot.fragment;
    this.currentStep = this.checkoutStepService.allSteps[this.checkoutStepService.getCurrentStepIndex(this.activatedRoute)];
    this.getCards();
    this.loadAddresses();
    this.initForm();
    this.isUpdating$ = this.createIsUpdating();
    this.resetFFAddress();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onChangeCurrentAddress(currentAddress: { address: ExtAddress }): void {
    this.floorPlanErrorMessage = [];

    if(!currentAddress || (currentAddress && currentAddress.address?.addressType?.code === AddressType.DROP_SHIP)) {
      return this.onChangeCurrentAddress$?.unsubscribe();
    }
    this.customLoadingSpinnerService.showSpinner();
    this.onChangeCurrentAddress$ = this.checkoutDeliveryAddressFacade.setDeliveryAddress(currentAddress.address)
      .pipe(
        take(1),
        switchMap(() => currentAddress.address?.addressType?.code === AddressType.FLOOR_PLAN ?
          this.checkoutService.checkPaymentPossibility(this?.cart?.user?.uid, this?.cart?.code, PaymentType.WELLS_FARGO).pipe(
            tap((response: CheckPaymentResponse) => {
              if(!response.available) {
                this.floorPlanErrorMessage = response.messages;
              }
            })
          ) : of(null)),
        switchMap(() => this.checkoutService
            .getFreightAddress(this?.cart?.user?.uid, this?.cart?.code)
            .pipe(take(1))
        ),
        catchError((err) => {
          this.globalMessageService.add(err.message, GlobalMessageType.MSG_TYPE_ERROR);
          this.customLoadingSpinnerService.hideSpinner();
          return EMPTY;
        })
      )
      .subscribe((freight) => {
        this.customLoadingSpinnerService.hideSpinner();
        if (freight.freightForwarderRequirement) {
          if (!freight.addresses) {
            this.globalMessageService.add(
              freight?.message,
              GlobalMessageType.MSG_TYPE_WARNING
            );
          } else {
            this.fFAddresses = freight.addresses;
            this.cdr.detectChanges();
            this.continueDisabled$.next(false);
          }
        } else {
          this.continueDisabled$.next(false);
        }
      });
  }

  resetFFAddress() {
    this.subscriptions.add(
      this.validationService.isAddressChange$
        .pipe(filter(Boolean))
        .subscribe(() => {
          this.fFAddresses = [];
          this.freightForwarderAddress = null;
          this.selectedFFDropdownValue = null;
          if (this.verifiedAddress && this.verifiedAddress.id) {
            try {
              delete this.verifiedAddress['id'];
            } catch (err) {
              console.log(err);
            }
          }
          this.cdr.detectChanges();
        })
    );
  }

  getCardContent(
      address: Address,
      selected: any,
      textDefaultDeliveryAddress: string,
      textShipToThisAddress: string,
      textSelected: string,
      textPhone: string,
      textMobile: string
  ): Card {
    let region = '';
    if (address.region && address.region.isocode) {
      region = address.region.isocode + ', ';
    }

    const numbers = getAddressNumbers(address, textPhone, textMobile);
    return {
      role: 'region',
      title: address.defaultAddress ? textDefaultDeliveryAddress : '',
      textBold: address.firstName + ' ' + address.lastName,
      text: [
        address.line1,
        address.line2,
        address.town + ', ' + region + address.country?.isocode,
        address.postalCode,
        numbers,
      ],
      actions: [{ name: textShipToThisAddress, event: 'send' }],
      header: selected && selected.id === address.id ? textSelected : '',
      label: address.defaultAddress
          ? 'addressBook.defaultDeliveryAddress'
          : 'addressBook.additionalDeliveryAddress',
    } as Card;
  }

  selectAddress(address: Address): void {
    this.globalMessageService.add(
        {
          key: 'checkoutAddress.deliveryAddressSelected',
        },
        GlobalMessageType.MSG_TYPE_INFO
    );

    this.setAddress(address);
  }

  addAddress(address: Address | undefined): void {
    if (!address) {
      return;
    }
    this.busy$.next(true);
    this.doneAutoSelect = true;

    this.subscriptions.add(
      this.checkoutService.setDeliveryAddress(this.selectedCard.address.id, this?.cart?.user?.uid, this.cart.code, address)
        .subscribe(
          () => {
          this.activeCartFacade.reloadActiveCart();
          this.getFreightForwardAddress(address);
        },
          (err) => this.onError())
    );
  }


  getFFCustomeAddress(fFFormValue: any) {
    this.fFAddressFormValue = fFFormValue.ffAddressValue;
    this.selectedFFDropdownValue = fFFormValue.selectedAddress;
  }

  onVerify(address: UntypedFormGroup | null) {
      if (typeof address === 'string') {
        this.verifiedAddress = null;
      } else {
        this.verifiedAddress = address?.getRawValue();
        this.cdr.detectChanges();
        this.showMessage('checkoutAddress.deliveryAddressSelected', GlobalMessageType.MSG_TYPE_INFO);
        this.setCheckoutDeliveryAddress();
      }
  }

  showMessage(key: string, msgType: GlobalMessageType) {
    this.globalMessageService.add({key: key},msgType);
  }

  private addYears(date: Date, years: number): Date {
    date.setFullYear(date.getFullYear() + years);
    return date;
  }

  private checkCurrentStepType(type: CheckoutStepType[] | CheckoutStepTypeExtended): boolean {
    return this.currentStep?.type == type;
  }

  next(): void {
    if (this.formGroup?.getRawValue()) {
      this.continueDisabled$.next(true);
      this.subscriptions.add(
        this.activeCartFacade
          .getActive()
          .pipe(
            take(1),
            switchMap((cart: Cart) => {
              return this.userAccount.get().pipe(
                switchMap((user) => {
                  if (this.subscriptionProducts?.length && this.formGroup.get(['subscriptions']) && this.checkCurrentStepType(CheckoutStepTypeExtended.SUBSCRIPTION_INFORMATION) ) {
                    let requests: Observable<any>[] = [];
                    let body : SubscriptionInformationItem;

                    this.formGroup.getRawValue()?.subscriptions?.forEach((subscription: any) => {
                      body = {...subscription, subEndDate: this.addYears(new Date(), 1) };

                      const request = this.subscriptionsService.setSubscriptionInformation(
                        user?.uid,
                        cart?.code,
                        body
                      );
                      requests.push(request);
                    });
                    requests.push(this.checkoutService.setCheckoutInformation(
                      { purchaseOrderNumber: this.formGroup.get('purchaseOrderNumber').value },
                      user?.uid,
                      cart.code
                    ))
                    return combineLatest(requests);
                  } else {
                    const body = {
                      ...this.formGroup.getRawValue()?.order,
                      singleConsignmentInfo:  {
                        state: this.formGroup.getRawValue()?.singleConsignment ?? false
                      }
                    };
                    return this.checkoutService.setCheckoutInformation(
                      body,
                      user?.uid,
                      cart.code
                    );
                  }
                })
              );
            }),
            switchMap(() => {
              if(this.saveDropShipAddress && this.verifiedAddress && this.cart?.dropShipAddress?.addressType?.code == AddressType.DROP_SHIP) {
                if(this.limitCountOfAddressesMessage) {
                  this.globalMessageService.add(this.limitCountOfAddressesMessage, GlobalMessageType.MSG_TYPE_WARNING);
                  return of({});
                }
                return this.checkoutService.addDropShipAddress(this.user.uid, this.verifiedAddress).pipe(
                  tap(() => {
                    this.showMessage('addressForm.userAddressAddSuccess', GlobalMessageType.MSG_TYPE_CONFIRMATION);
                  })
                );
              }
              return of({});
            }),
            switchMap(() => {
              this.hasToSkipCart = true;
              const paymentType = this.selectedCard?.address?.addressType?.code === AddressType.FLOOR_PLAN ?
                PaymentType.WELLS_FARGO :
                PaymentType.CARD;
              return this.checkoutPaymentTypeFacade
                .setPaymentType(paymentType)
                .pipe(
                  tap(() => this.setCheckoutDeliveryAddress()),
                  catchError((err) => {
                    this.globalMessageService.add(
                      'This material is not currently available to you based on regional restrictions.  Please contact your Sales Representative for more information.',
                      GlobalMessageType.MSG_TYPE_ERROR
                    );
                    this.customLoadingSpinnerService.hideSpinner();
                    return EMPTY;
                  }),
                );
            })
          ).subscribe({
            error: () => {
              this.hasToSkipCart = true;
              this.continueDisabled$.next(false);
            },
            complete: () => this.hasToSkipCart = true
          })
      );
    }
  }

  saveDropShipAddressChanged(value: boolean) {
    this.saveDropShipAddress = value;
  }

  setCheckoutDeliveryAddress() {
    if(!this.selectedCard && ((this.isSubscription || this.isCartMixed) && this.checkCurrentStepType(CheckoutStepTypeExtended.SUBSCRIPTION_INFORMATION))) {
      this.checkoutStepService.next(this.activatedRoute);
      return;
    }
    if(this.selectedCard?.address?.addressType?.code !== AddressType.DROP_SHIP) {
      const hasFFAddress = this.fFAddresses?.length > 0;
      if (hasFFAddress) {
        this.addFFaddress(this.fFAddressFormValue);
      } else {
        this.setAddress(this.selectedCard.address, !hasFFAddress)
      }
    } else {
      if(this.verifiedAddress?.id) {
        if(this.fFAddressFormValue) {
          this.addFFaddress(this.fFAddressFormValue);
        } else {
          this.checkoutStepService.next(this.activatedRoute);
        }
      } else {
        this.setAddressedByValue();
      }
    }
    this.googleAnalyticsService.sendGaEvent('add_shipping_info', {
      currency: this.cart.subTotal.currencyIso,
      value: this.cart.totalPriceWithTax.value,
      orderDiscount: this.cart?.promoDiscounts?.value < 0 ? Math.abs(this.cart?.promoDiscounts?.value) : undefined,
      coupon: this.cart?.appliedVouchers?.length ? this.cart?.appliedVouchers[0].voucherCode : undefined,
      items: this.googleAnalyticsService.buildGaItems(this.cart?.entryGroups, this.cart?.appliedProductPromotions)
    });
  }

  nextStep() {
    this.checkoutStepService.next(this.activatedRoute);
  }

  setAddressedByValue() {
    if(this.fFAddresses?.length && this.fFAddressFormValue && this.selectedCard?.address?.addressType?.code !== AddressType.DROP_SHIP) {
      this.addFFaddress(this.fFAddressFormValue);
    } else {
      this.addAddress(this.verifiedAddress);
    }
  }

  private addFFaddress(fFAddresses: any): void {
    if(!this.selectedFFDropdownValue) {
      return;
    }
    fFAddresses['originalFreightForwarderAddressId'] = this.selectedFFDropdownValue?.originalFreightForwarderAddressId ?? this.selectedFFDropdownValue.id;
    this.checkoutService.addFFAddress(this?.user?.uid, this.cart.code, fFAddresses)
      .pipe(take(1))
      .subscribe(
        () => this.nextStep(),
        () => this.onError()
      );
  }

  back(): void {
    this.checkoutStepService.back(this.activatedRoute);
  }

  backToSubs(): void {
    this.router.navigate(['/my-account/subscriptions']);
  }

  addressSearchFn(term: string, item: CardWithAddress) {
    const loverTerm = term.toLocaleLowerCase();
    return item?.address?.addressType?.name?.toLocaleLowerCase().includes(loverTerm) ||
      item?.address?.companyName?.toLocaleLowerCase().includes(loverTerm) ||
      item?.address?.formattedAddress?.toLocaleLowerCase().includes(loverTerm);
  }

  private getSubscriptions(cartEntries: OrderEntry[]): void {
    if(this.checkCurrentStepType(CheckoutStepTypeExtended.SUBSCRIPTION_INFORMATION)) { 
      this.subscriptionProducts = this.findSubscriptionsProductInOrderEntries(cartEntries);
    }
  }

  private findSubscriptionsProductInOrderEntries(cartEntries: OrderEntry[]): SubscriptionProduct[] {
    return cartEntries?.filter((entry: OrderEntry) => (entry.product as SubscriptionProduct)?.serviceProductType && (entry.product as SubscriptionProduct)?.sapMaterialNumber)
      .map(entry => entry.product as SubscriptionProduct);
  }

  private getCards(): void {
    let firstLoad = true;
    this.subscriptions.add(
      this.activeCartFacade
        .getActive()
        .pipe(
          tap((cart: CartExtended) => {
            this.cart = cart;
            this.isSubscription = this.hasOnlySubscription(this.cart?.cartTypes);
            this.isCartMixed = this.hasMixedProducts(this.cart?.cartTypes);
            this.checkoutService.disableCheckoutSteps(this.cart?.cartTypes);
            this.getSubscriptions(this.cart?.entries);
            if(this.currentStep.id == 'deliveryAddress' && this.isSubscription) {
              this.routing.go("/checkout/subscription-info");
              this.currentStep = this.checkoutStepService.getCheckoutStep(CheckoutStepTypeExtended.SUBSCRIPTION_INFORMATION as unknown as CheckoutStepType);
            }
            if(firstLoad) {
              this.sendBeginCheckoutGaEvent(this.cart);
            }
            this.internalCalculation = this.cart.internalCalculation;
            this.cdr.markForCheck();
            this.cards$ = this.createCards().pipe(
              filter(v => {
                if (this.hasToSkipCart) {
                  this.hasToSkipCart = false;
                  return false;
                }
                return true;
              }),
              map((cards: CardWithAddress[]) => {
                return this.isSubscription
                  ? cards.filter(
                      (card) =>
                        card.address.addressType.code !== AddressType.DROP_SHIP
                    )
                  : cards;
              }),
              tap((x) => {
                let dropShipAddress = x.find((y) =>
                  this.cart?.dropShipAddress?.id == y.address.id
                    ? y
                    : !!y.card.header
                );
                let deliveryAddress = cart.deliveryAddress;
                if (dropShipAddress) {
                  if (!this.selectedCard) {
                    this.selectedCard = dropShipAddress;
                  } else if (
                    this.selectedCard?.address?.addressType?.code ==
                    dropShipAddress?.address?.addressType?.code
                  ) {
                    this.selectedCard = dropShipAddress;
                  }

                  if (firstLoad && this.selectedCard.address?.addressType?.code === AddressType.FLOOR_PLAN) {
                    firstLoad = false;
                    this.floorPlanErrorMessage = [];
                    this.checkoutService.checkPaymentPossibility(this?.cart?.user?.uid, this?.cart?.code, PaymentType.WELLS_FARGO)
                      .subscribe((response: CheckPaymentResponse) => {
                        if(response.available) {
                          this.checkoutDeliveryAddressFacade.setDeliveryAddress(this.selectedCard.address);
                          return;
                        }
                        this.floorPlanErrorMessage = response.messages;
                        this.customLoadingSpinnerService.hideSpinner();
                        this.continueDisabled$.next(false);
                      })
                  }

                  this.verifiedAddress = deliveryAddress
                    ? deliveryAddress
                    : dropShipAddress.address;

                  if (!this.cart?.freightForwarderAddress) {
                    this.getFreightForwarderAddresses();
                  }
                  if (
                    this.verifiedAddress &&
                    this.cart?.freightForwarderAddress &&
                    !this?.fFAddresses?.length
                  ) {
                    this.freightForwarderAddress = this.cart?.freightForwarderAddress;
                    this.fFAddressFormValue = this.freightForwarderAddress;
                    this.fFAddresses = [this.freightForwarderAddress];
                    this.getFreightForwarderAddresses();
                  }
                } else if (!deliveryAddress) {
                  this.selectedCard = null;
                } else if(!this.selectedCard && deliveryAddress) {
                  this.selectedCard = x.find((y) => deliveryAddress.id == y.address.id);
                  this.getFreightForwarderAddresses();
                }
              })
            );
          })
        )
        .subscribe()
    );
  }

  hasOnlySubscription(cartTypes: CartTypes[] | undefined): boolean {
    return cartTypes?.length === 1 && cartTypes?.some(ct => ct === CartType.SUBSCRIPTION.toString());
  }

  hasMixedProducts(cartTypes: CartTypes[] | undefined): boolean {
    return cartTypes?.length > 1 && cartTypes?.some(ct => ct === CartType.SUBSCRIPTION.toString());
  }

  getFreightForwardAddress(address: Address | undefined): void {
    if (!address) {
      return;
    }
    this.subscriptions.add(
      this.checkoutService.getFreightAddress(this?.cart?.user?.uid, this?.cart?.code)
        .pipe(take(1))
        .subscribe((res) => {
          if (res.freightForwarderRequirement) {
            if (!res.addresses) {
              this.globalMessageService.add(res?.message, GlobalMessageType.MSG_TYPE_WARNING);
              this.continueDisabled$.next(true);
            } else {
              this.fFAddresses = res.addresses
              this.cdr.detectChanges();
              this.continueDisabled$.next(false);
            }
          }
        },
          err => this.onError()
        )
      );
  }

  addressLoaded() {
    if(this.fieldToHighlight) {
      this.viewportScroller.scrollToAnchor(this.fieldToHighlight);
      this.fieldToHighlight = null;
    }
  }

  private getFreightForwarderAddresses() {
    if (this.cart?.deliveryAddress || this.cart?.dropShipAddress) {
      this.checkoutService
        .getFreightAddress(this?.cart?.user?.uid, this?.cart?.code)
        .pipe(take(1))
        .subscribe((freight) => {
          if (freight.freightForwarderRequirement) {
            if (!freight.addresses) {
              this.globalMessageService.add(
                freight?.message,
                GlobalMessageType.MSG_TYPE_WARNING
              );
            } else {
                this.fFAddresses = freight.addresses;

                if(!this.compareTwoArrayOfObjects(this.fFAddresses, freight.addresses)) {
                  this.fFAddresses =[...this.fFAddresses, ...freight.addresses];
                }
              }
              this.cdr.detectChanges();
          } else {
            this.continueDisabled$.next(false);
          }
        });
    }
  }

  limitCountOfAddressesMessageChange(message: string): void {
    this.limitCountOfAddressesMessage = message;
  }

  compareTwoArrayOfObjects(
    first_array_of_objects: any,
    second_array_of_objects: any
    )  {
        return (
            first_array_of_objects.length === second_array_of_objects.length &&
            first_array_of_objects.every((element_1: any) =>
                second_array_of_objects.some(
                    (element_2: any) =>
                    element_1.addressType.code === element_2.addressType.code
                )
            )
        );
    };

  protected initForm(): void {
    this.formGroup = new FormGroup({});

    this.subscriptions.add(
      this.activeCartFacade.getActive().pipe(
        take(1),
        switchMap(cart => {
          return this.userAccount.get().pipe(switchMap(user => {
            this.user = user;
            if (this.subscriptionProducts?.length > 0 && this.checkCurrentStepType(CheckoutStepTypeExtended.SUBSCRIPTION_INFORMATION)) {
              return this.subscriptionsService.getSubscriptionInformation(user?.uid, cart?.code)
                .pipe(
                  tap((subscriptionInfo: any) => this.subscriptionInfo = subscriptionInfo?.subscriptions)
                )
            }
            return this.checkoutService.getCheckoutInfo(cart?.code, user?.uid);
          }));
        })
      ).subscribe(info => {
          this.checkoutInfo = info?.purchaseOrderNumber ? info : { purchaseOrderNumber: this.cart?.contactInformation?.purchaseOrderNumber };
          this.singleConsignmentInfo = info?.singleConsignmentInfo;
          this.shipOnceCheckbox.setValue(info?.singleConsignmentInfo?.state);
          if (!this.subscriptionProducts) {
            this.formGroup.addControl('singleConsignment', this.shipOnceCheckbox);
          }
          if (info?.singleConsignmentInfo?.disabled) {
            this.shipOnceCheckbox.disable();
          }
          this.isFormInitialized = true;
          this.cdr.markForCheck();
      })
    );
  }

  protected loadAddresses(): void {
    if (!this.isGuestCheckout) {
      this.userAddressService.loadAddresses();
    }
  }

  protected createCards(): Observable<CardWithAddress[]> {
    const translations$ = combineLatest([
      this.translationService.translate('checkoutAddress.defaultDeliveryAddress'),
      this.translationService.translate('checkoutAddress.shipToThisAddress'),
      this.translationService.translate('addressCard.selected'),
      this.translationService.translate('addressCard.phoneNumber'),
      this.translationService.translate('addressCard.mobileNumber'),
    ]);

    return combineLatest([this.getSupportedAddresses(), translations$]).pipe(
        tap(([addresses]) => this.selectDefaultAddress(addresses)),
        map(
            ([
               addresses,
               [textDefault, textShipTo, textSelected, textPhone, textMobile],
             ]) =>
                addresses?.map((address) => ({
                  address,
                  card: this.getCardContent(
                      address,
                      null,
                      textDefault,
                      textShipTo,
                      textSelected,
                      textPhone,
                      textMobile
                  ),
                  disabled: address.erpStatus === 'BLOCK'
                }))
        )
    );
  }

  protected selectDefaultAddress(
      addresses: ExtAddress[],
      selected?: Address | undefined
  ): void {
    if (
        !this.doneAutoSelect &&
        addresses?.length &&
        (!selected || Object.keys(selected).length === 0)
    ) {
      selected = addresses.find((address) => address.defaultAddress);
      if (selected) {
        this.setAddress(selected);
      }
      this.doneAutoSelect = true;
    }
  }

  protected getSupportedAddresses(): Observable<ExtAddress[]> {
    return this.userAddressService.getAddresses() as Observable<ExtAddress[]>;
  }

  protected createIsUpdating(): Observable<boolean> {
    return combineLatest([
      this.busy$,
      this.userAddressService.getAddressesLoading(),
      this.getAddressLoading(),
    ]).pipe(
        map(
            ([busy, userAddressLoading, deliveryAddressLoading]) =>
                busy || userAddressLoading || deliveryAddressLoading
        ),
        distinctUntilChanged()
    );
  }

  protected getAddressLoading(): Observable<boolean> {
    return this.checkoutDeliveryAddressFacade.getDeliveryAddressState().pipe(
        map((state) => state.loading),
        distinctUntilChanged()
    );
  }

  protected setAddress(address: ExtAddress, shouldRedirect?: boolean): void {
    this.busy$.next(true);
    this.subscriptions.add(this.checkoutDeliveryAddressFacade
        .setDeliveryAddress(address)
        .subscribe({
          complete: () => this.onSuccess(shouldRedirect),
          error: () => this.onError(),
        }));
  }

  protected onSuccess(shouldRedirect?: boolean): void {
    this.busy$.next(false);

    if (shouldRedirect) {
      this.nextStep();
    }
  }

  protected onError(): void {
    this.busy$.next(false);
    this.continueDisabled$.next(false);
  }

  private sendBeginCheckoutGaEvent(cart: CartExtended): void {
    if(cart) {
      this.googleAnalyticsService.sendGaEvent('begin_checkout', {
        currency: cart.subTotal?.currencyIso,
        value: cart.totalPriceWithTax?.value,
        orderDiscount: this.cart?.promoDiscounts?.value < 0 ? Math.abs(this.cart?.promoDiscounts?.value) : undefined,
        coupon: cart.appliedVouchers?.length ? cart.appliedVouchers[0].voucherCode : undefined,
        items: this.googleAnalyticsService.buildGaItems(cart?.entryGroups, cart?.appliedProductPromotions)
      });
    }
  }
}
