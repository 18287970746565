import { ChangeDetectionStrategy, Component, ElementRef, HostListener } from '@angular/core';
import { CommonModule } from "@angular/common";
import {
  FocusConfig,
  FormErrorsModule,
  ICON_TYPE,
  IconModule,
  KeyboardFocusModule,
  LaunchDialogService,
  SpinnerModule
} from "@spartacus/storefront";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { I18nModule } from "@spartacus/core";
import { Subscription } from "rxjs";
import { LoadingEnum } from "../../../enums/loading.enum";
import { ContactFactoryInfo } from "../../../interfaces/contact-info";
import { validateEmail } from "../../validators/validate-email";
import { AccountShortcutModule } from "../../pipes/account-shortcut/account-shortcut.module";

@Component({
  selector: 'cx-contact-factory-dialog',
  templateUrl: './contact-factory-dialog.component.html',
  styleUrls: ['./contact-factory-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconModule,
    FormErrorsModule,
    ReactiveFormsModule,
    FormsModule,
    I18nModule,
    KeyboardFocusModule,
    SpinnerModule,
    AccountShortcutModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactFactoryDialogComponent {
  iconTypes = ICON_TYPE;
  loading = LoadingEnum.Idle;
  LoadingEnum = LoadingEnum;
  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button',
    focusOnEscape: true,
  };
  contactFactoryInfo: ContactFactoryInfo;
  formGroup: FormGroup;
  isThankYouView = false;
  isAreYouSureMessageVisible = false;
  private subscription = new Subscription();

  @HostListener('click', ['$event'])
  handleClick(event: UIEvent): void {
    if ((event.target as any).tagName === this.el.nativeElement.tagName) {
      this.closeWithCheck('Cross click');
    }
  }

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.createForm();
    this.getLaunchDialogData();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.close('close dialog');
  }

  submitContactFactoryRequest() {
    this.loading = LoadingEnum.Loading;
    setTimeout(() => {
      this.loading = LoadingEnum.Loaded;
      this.isThankYouView = true;
      this.formGroup.reset();
    }, 2000);
  }

  closeWithCheck(reason: string,): void {
    if(this.loading === LoadingEnum.Loading) {
      return;
    }
    if (this.checkFormGroupText(this.formGroup)) {
      this.isAreYouSureMessageVisible = true;
      return;
    }
    this.launchDialogService.closeDialog(reason || 'close dialog');
  }

  close(reason?: string) {
    this.isAreYouSureMessageVisible = false;
    this.launchDialogService.closeDialog(reason || 'close dialog');
  }

  toggleAreYouSureMessage() {
    this.isAreYouSureMessageVisible = false;
  }

  private createForm() {
    this.formGroup = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, validateEmail]],
      phoneNumber: [null, Validators.required],
      modelNumber: [null, Validators.required],
      serialNumber: [null, Validators.required],
      comments: [null, Validators.required]
    })
  }

  private checkFormGroupText(formGroup: FormGroup): boolean {
    let hasText = false;

    for (const controlName in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(controlName)) {
        const controlValue = formGroup.controls[controlName].value;

        if (controlValue && controlValue.trim() !== '') {
          hasText = true;
          break;
        }
      }
    }

    return hasText;
  }

  private getLaunchDialogData() {
    this.subscription.add(
      this.launchDialogService.data$
        .subscribe((contactFactoryInfo: ContactFactoryInfo) => this.contactFactoryInfo = contactFactoryInfo)
    );
  }

}
