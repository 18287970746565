export const customEnglishTranslations = {
  product: {
    addToCart: {
      inStock: 'Currently In-Stock',
      quantity: 'Quantity',
      buy: 'Buy',
      add: 'Add',
    },
    productSummary: {
      id: 'Model',
    },
    productList: {
      product: 'Product',
      priceMsrp: 'Price (MSRP)',
      priceMslp: 'Price (MSLP)',
      quantity: 'Quantity',
      tooltipLtlMessage: 'Indicates this item must ship via LTL truck; it cannot ship via UPS',
      tooltipFlammableMessage: 'Flammable item; must ship via ground',
      noResults: 'No results were found for your search query',
      fileType: 'File Type',
      fileName: 'File Name',
      itemNumber: 'Item Number',
      language: 'Language',
    },
    productDetails: {
      id: 'Model',
      title: 'downloads:',
      link: 'Spec Sheet',
    },
    itemCounter: {
      infoMessage: {
        step: 'Must be ordered in increments of {{step}}.',
        min: 'The product has a minimum quantity of {{min}}.',
        max: 'The product has a maximum quantity of {{max}}.',
        maxBundleQty: 'The bundle has a maximum quantity of {{max}} to purchase'
      },
    },
    bundle: {
      addToCartSuccess: 'Bundle was added successfully',
      addToCartFailed: 'Unfortunately, it\'s not possible to add product to the cart at the moment. Please try again later or contact us {{phoneNumber}}',
      title: 'Buy This Item In A Bundle'
    },
    addToWishList: {
      add: 'Add To Favorites',
      remove: 'Saved To Favorites',
      noFavorites: 'No Favorites Yet',
      addToFavoriteModal: {
        title: 'Add To Favorite',
        selectList: 'Select List',
        add: 'Add',
        save: 'Save',
        infoMessage: 'Your selection will be automatically saved',
        addedLabel: 'Added',
        removedLabel: 'Removed',
      },
      favoriteList: {
        title: 'Your Lists',
        addNewList: 'Add New List',
        newListTitle: 'New List',
        listName: 'List Name',
        enterListName: 'Enter List Name',
        removeAllProductsFromList: 'Remove All Products from the List',
        editListName: 'Edit List Name',
        deleteList: 'Delete List',
        deleteListConfirmationText: 'Are you sure you want to delete the “{{listName}}” list?',
        deleteListInfoText: 'Deleting a list will delete all products contained in that list',
        removeAllItemsFromListConfirmationText: 'Do you want to remove all products from the "{{listName}}" list?',
        delete: 'Delete',
        remove: 'Remove',
        addAllToCartBtn: 'Add All to Cart',
        addAllToCartConfirmationText: 'Are you sure you want to add all items from the "{{listName}}" list to cart?',
        favoritesAddedToCart: `{{productsAmount}} favorite products have been added to the cart`,
      }
    },
    TabPanelContainer: {
      tabs: {
        WarrantyApplicableProductsComponent: 'Product Current Warranty Coverage',
        ApplicableProductsComponent: 'Applicable Products',
      },
    },
  },
  importExport: {
    importEntriesDialog: {
      summary: {
        loadedToCart: 'Product has been loaded to cart {{ cartName }}'
      }
    },
    exportEntries: {
      exportProductToCsv: 'Export to CSV',
      columnNames: {
        price: 'Total price',
        unitPrice: 'Unit price',
        code: 'Model'
      }
    }
  },
  userAccount: {
    miniLogin: {
      signInRegister: 'Sign In',
    },
  },
  cart: {
    cartDetails: {
      empty: {
        title: 'Your shopping cart is empty',
        description:
          'Before starting a checkout, you must add some products to your cart. You will find a lot of interesting products in our catalog.',
        youMayLike: 'You may also like',
      },
    },
    cartItems: {
      id: 'Model',
      orderItems: 'Order Items ({{count}} item)'
    },
    voucher: {
      coupon: 'Have a promo code?',
    },
    orderCost: {
      subTotal: 'Subtotal:',
      specialHandling: 'Special Handling:',
      taxes: 'Taxes:',
      adjustment: 'Adjustment:',
      tradeDiscounts: 'Trade Discounts:',
      promoDiscounts: 'Promo Discounts:',
      grandTotal: 'Grand total:',
      tbd: 'TBD',
    },
  },
  checkoutScheduledReplenishment: {
    checkoutScheduledReplenishment: {
      autoReplenishOrder: 'Auto Replenishment Orders',
      every: 'Every # Of Days'
    }
  },
  quickOrder: {
    quickOrderCartForm: {
      productCodePlaceholder: 'Enter Model',
      entryProductCode: 'Enter Product Model for Quick Order',
      productCodeLabel: 'Product Model',
    },
    quickOrderForm: {
      id: 'Model {{id}}',
      msrp: 'MSRP',
      productNoPrice:
        "Unfortunately, it's not possible to add product to the cart at the moment. Please try again later or contact us {{phoneNumber}}",
    },
    quickOrderTable: {
      id: 'Model {{id}}',
      msrp: 'MSRP',
    },
  },
  checkout: {
    checkout: {
      backToCart: 'Back',
    },
    checkoutProgress: {
      deliveryAddress: 'Delivery Address',
      specialHandlingTab: 'Special Handling',
      specialHandling: {
        specialHandlingOptions: 'Special Handling options',
        selectOptions: 'Select your Special Handling Options:',
        residentialDeliverySelected: 'Residential Delivery has been pre-selected based on your address.',
      },
      subscriptionInfo: 'Subscription Information',
      paymentDetails: 'Payment Details',
      reviewOrder: 'Review Order',
    },
    checkoutAddress: {
      shippingAddress: 'Delivery Address',
      shipToThisAddress: 'Ship Here',
      details: 'Order Details',
      powerPlayCreation: 'PowerPlay User Creation',
      powerPlayCreationMixedCartInfo: 'First Name and Last Name provided on this step related only to Subscription product.',
      defaultDeliveryAddress: 'Default',
      detailsForm: {
        poNumber: 'P.O. Number',
        poNumberPlaceholder: 'Enter P.O',
        firstName: 'First Name',
        firstNamePlaceholder: 'Enter Contact First Name',
        firstNamePowerPlaySellingPlaceholder: 'Enter First Name',
        lastNamePowerPlaySellingPlaceholder: 'Enter Last Name',
        lastName: 'Last Name',
        lastNamePlaceholder: 'Enter Contact Last Name',
        contactPhone: 'Contact Phone',
        contactPhonePlaceholder: 'Enter Contact Phone',
        role: 'Role',
        rolePlaceholder: 'Select Role',
        email: 'Email',
        emailPlaceholder: 'Enter Email',
        renewSubscription: 'Renew Subscription',
        renewSubscriptionPlaceholder: 'Select Subscription to renew',
        shipOnceCheckbox:
          'Order will ship once all items on order are in stock.',
        shipOnceCheckboxTitle:
          'All orders will ship as product becomes available unless complete shipping is chosen below.',
        shipOnceReplenishmentCheckboxTitle: `All items on this order will be shipped together because a Desired Shipping Date has been selected. All products in this order will not be shipped until your specified shipping date. To receive available product as early as possible, please remove your Desired Shipping Date.`,
        shipOnceReplenishmentNoteCheckboxTitle: 'Please note: Your entire order will not ship until all products are available, even if that availability date is after the Desired Shipping Date.',
        shipOnceOneLineItemCheckboxTitle:
          'This order contains only one line item; it will ship complete.',
      },
      ffAddress: 'Freight Forwarder',
      validateAddressErrorMessage:
        'The entered address does not match any existing address. To continue, enter the address correctly, please.',
      invalidAddress:
        'The house number or apartment/suit/unit number for this address is missing or invalid.',
      addressNotValidatedErrorMessage: 'Your Address is not validated. Please check it',
      subscriptionDetails: 'Subscription Details',
      subscriptionDetailsInfo: 'Details below will be used to create a Subscription.',
      currentlyAssignedTo: 'Currently assigned to: ',
    },
    checkoutOrderConfirmation: {
      confirmationOfOrder: 'Confirmation of Order:',
      thankYou: 'Thank you for your order!',
      invoiceHasBeenSentByEmail:
        'Order confirmation has been sent by email. You should receive it soon.',
      callToRepresentativeDAOrder:
        'Please call your onboarding representative for next steps in establishing your account.',
      creditCardPaymentInfoDAOrder:
        'You will need to enter your credit card details after clicking "Place Order."',
      orderItems: 'Order Items',
      orderPlacedSuccessfully: 'Order placed successfully',
      createAccount: 'Create an account?',
      placeTestOrder: 'Place Test Order',
      createAccountForNext:
        'Create an account for <{{email}}> for a faster checkout on your next visit.',
    },
    checkoutReview: {
      infoMessage:
        'The cost has been recalculated, pay attention to the order summary to see the details',
      offlineInfoMessage:
        'Pricing is not currently available, order will be placed with MSRP pricing',
      contactInformation: 'Contact Information',
      endDate: 'End Date',
      frequency: 'Frequency',
      nextOrder: 'Next Order Date',
      desiredDate: 'Desired Shipment Date',
      errorPlacingOrder: "Order wasn't placed. Please try again.",
      successPlacingOrder: 'Order was successfully placed',
      confirmThatRead: 'I am confirming that I have read and agree with the'
    },
  },
  payment: {
    paymentTypes: {
      title: 'Select your Payment Type:',
      paymentType_CARD: 'Credit Card',
      paymentType_ACCOUNT: 'Open Account',
      paymentType_WELLS_FARGO: 'Wells Fargo Financing',
      paymentType_BANK_TRANSFER: 'Pay in Advance (PIA)',
    },
  },
  order: {
    orderDetails: {
      purchaseOrderNumber: 'P.O. Number',
      paymentDetails: 'Payment Details',
      freightForwarderAddress: 'Freight Forwarder',
      deliveryAddress: 'Delivery Address',
      shipTo: 'Ship To'
    },
  },
  common: {
    httpHandlers: {
      netPriceError:
        'NET price is currently not available at this time.  Please try again later or contact us at {{phoneNumber}} to confirm this product is available for you to purchase.',
      domainError:
        "Unfortunately, it's not possible to add product to the cart at the moment. Please try again later or contact us {{phoneNumber}}",
      b2bUnitChanged: 'Cart has been updated for the current company.',
      validationErrors: {
        invalid: {
          code: 'Sorry the page or product you are looking for cannot be found.',
          purchaseOrderNumber: 'Please enter a valid P.O. Number'
        }
      },
      voucherAlreadyExist: 'Promo Code already applied.',
      voucherNotQualifyError: 'Promo Code does not qualify.',
      voucherExpiredError: 'Promo Code expired.'
    },
    pageMetaResolver: {
      favorites: {
        title: 'Favorites',
      },
      savedAddresses: {
        title: 'Saved Addresses',
      },
      category: {
        titleReferences: '{{refType}} for {{productName}}',
      }
    },
    formErrors: {
      email: 'Is not a valid email address',
      duplicate: 'This email address is already in use. Please enter an email that is not being used by an active user or purchase a PowerPlay renewal.'
    },
    common: {
      apply: 'Apply',
      yes: 'Yes',
    },
    carousel: {
      viewAll: 'View All',
    },
    searchBox: {
      placeholder: 'Enter Product Name or Model',
      spSearchBox: {
        placeholderModelNumber: "Enter Your Generac Model Number, example: G00722310",
        placeholderSerialNumber: "Enter Your Serial Number",
      }
    },
  },
  savedCart: {
    savedCartList: {
      myCart: 'My Cart',
      sharedCarts: 'Shared Carts',
      owner: 'Owner',
    },
  },
  address: {
    addressForm: {
      selectFromSavedAddresses: 'Select From Saved Addresses',
      saveDropShipAddress: 'Save Drop Ship Address',
      company: 'Company Name',
      firstName: {
        label: 'First Name',
      },
      lastName: {
        label: 'Last Name',
      },
      state: 'State/Province',
      phoneNumber: {
        label: 'Phone Number',
        placeholder: 'Phone Number',
      },
    },
    addressCard: {
      deliveryAddress: 'Delivery Address',
      shipTo: 'Ship To'
    }
  },
  savedAddress: {
    savedAddress: {
      company: 'Company',
      name: 'Name',
      address: 'Address',
      phone: 'Phone',
      editAddress: 'Edit Address',
      deleteAddress: 'Delete Address',
      deleteAddressConfirmationMessage: 'Do you want to delete the address?',
      addAddress: 'Add Address',
    }
  },
  contactFactory: {
    contactFactory: {
      title: "Contact Factory",
      subTitle: "Use from below to submit your part question to the Service department.",
      serviceText: "For other service-related inquiries, please call",
      accountNumber: "Account Number",
      companyName: "Company Name",
      address: "Address",
      firstName: {
        label: "First Name",
        placeholder: "Enter First Name",
      },
      lastName: {
        label: "Last Name",
        placeholder: "Enter Last Name",
      },
      email: {
        label: "Email",
        placeholder: "Enter Email",
      },
      daytimePhone: {
        label: "Daytime Phone",
        placeholder: "Enter Phone",
      },
      subject: "Subject",
      itemNumber: "Item Number",
      itemDescription: "Item Description",
      modelNumber: {
        label: "Model Number",
        placeholder: "Enter Model Number",
      },
      serialNumber: {
        label: "Serial Number",
        placeholder: "Enter Serial Number",
      },
      comments: {
        label: "Questions/ Comments",
        placeholder: ""
      },
      cancel: "Cancel",
      send: "Send",
      stay: "Stay",
      leave: "Leave",
    }
  },
};
