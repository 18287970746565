<ng-container *ngIf="product">
  <a
    [routerLink]="product.routerLink ? product.routerLink : { cxRoute: 'product', params: product } | cxUrl"
    [queryParams]="{gaListName: gaListName}"
    (click)="selectItem(product)"
    class="cx-product-image-container"
    [class.disabled-link]="isSubs"
    tabindex="-1"
  >
    <cx-media
      class="cx-product-image"
      [class.sub-non-image]="isSubs && !product.images?.PRIMARY.product"
      [container]="product.images?.PRIMARY"
      format="product"
      [alt]="product.summary"
    ></cx-media>
  </a>
  <a
    *ngIf="!isSubs"
    [routerLink]="product.routerLink ? product.routerLink : ({ cxRoute: 'product', params: product } | cxUrl)"
    [queryParams]="{gaListName: gaListName}"
    (click)="selectItem(product)"
    class="cx-product-name"
    [innerHTML]="product.name"
  ></a>

  <a
    *ngIf="isSubs"
    class="cx-product-name"
    [innerHTML]="product.name"
  ></a>

  <span
    *ngIf="isSubs"
    class="summary"
    [innerHTML]="product.summary"
  ></span>

  <ng-template [cxOutlet]="ProductListOutlets.ITEM_DETAILS">
    <div *ngIf="product.purchasable || isSubs" class="cx-product-price-container">
      <div
        class="cx-product-price"
        [attr.aria-label]="'productDetails.productPrice' | cxTranslate"
        [class.mb-0]="product.bundleAvailable"
      >
        {{ product.price?.formattedValue }}
        <span *ngIf="!isSubs" class="price-label">{{ product.servicePart ? 'MSLP' : 'MSRP'}}</span>
        <cx-info-price-wrapper
                *ngIf="!isSubs"
                [product]="product"
                [position]="TooltipPosition.Right"
        ></cx-info-price-wrapper>
      </div>
      <a
        *ngIf="!isSubs && product.bundleAvailable"
        [routerLink]="{ cxRoute: 'bundles', params: { productCode: product.code, name: product.slug } } | cxUrl"
        class="buy-in-bundle call-for-pricing link cx-action-btn-link">
        Buy In Bundle >
      </a>
    </div>
    <div *ngIf="!isSubs && !product.purchasable && !product?.servicePart" class="cx-product-price-container non-price">
      <div class="contact-us">
        Please contact us for pricing details.
      </div>
    </div>
  </ng-template>

  <cx-add-to-cart 
    *ngIf="product?.purchasable"
    [product]="product"
    [purchasable]="true"
    [productCode]="product.code"
    [showQuantity]="false"
    [gaListName]="gaListName"
    [gaProductCategories]="gaProductCategories"
    [isSubs]="isSubs"
    [isAddModal]="isAddModal"
    (onAddModalAdded)="onAddModalAdded.emit($event)"
  ></cx-add-to-cart>

  <div *ngIf="!product.purchasable && !isSubs">
    <add-to-favorites [product]="product" [gaListName]="gaListName" [gaProductCategories]="gaProductCategories"></add-to-favorites>
    <div class="product-ltl" *ngIf="product.ltl">
      <ng-container *ngIf="(tooltipLtlMessage$ | async) as tooltipLtlMessage">
        <div class="position-relative" (mouseenter)="ltlMessage=tooltipLtlMessage"
          (mouseleave)="ltlMessage=''">
            <img src="../../../../../assets/icons/truck_icon_24.svg">
            <cx-info-message [message]="ltlMessage" [position]="'position-right'"></cx-info-message>
        </div>
      </ng-container>
    </div>
    <div *ngIf="product.flammable" class="product-flammable">
      <ng-container *ngIf="(tooltipFlammableMessage$ | async) as tooltipFlammableMessage">
        <div class="position-relative" (mouseenter)="flammableMessage=tooltipFlammableMessage" (mouseleave)="flammableMessage=''">
          <img class="flammable-icon" src="../../../../../assets/icons/flammable_icon_24.svg">
          <cx-info-message [message]="flammableMessage" [position]="'position-right'"></cx-info-message>
        </div>
      </ng-container>
    </div>
    <div
            class="position-relative non-price-status"
            (mouseenter)="callForPricingMessage=tooltipMessage"
            (mouseleave)="callForPricingMessage=''"
            [class.service-parts]="product?.servicePart"
    >
      <ng-container *ngIf="product?.servicePart; else regularProduct;">
        <a
                *ngIf="product?.unavailabilityDetail?.type === UnavailabilityDetailType.ContactFactory; else plainText;"
                class="call-for-pricing link cx-action-btn-link"
                (click)="openContactFactory()"
        >
          {{product?.unavailabilityDetail?.label}}
        </a>

        <cx-info-price-wrapper
                *ngIf="!isSubs"
                [product]="product"
                [position]="TooltipPosition.Right"
        ></cx-info-price-wrapper>

        <ng-template #plainText>
          <span class="call-for-pricing">{{product?.unavailabilityDetail?.label}}</span>
        </ng-template>
      </ng-container>

      <ng-template #regularProduct>
        <div
                class="position-relative"
                (mouseenter)="callForPricingMessage=tooltipMessage"
                (mouseleave)="callForPricingMessage=''">
          <a
                  [href]="contactForPricingHref"
                  class="call-for-pricing link cx-action-btn-link">
            Contact For Pricing
            <cx-info-message [message]="callForPricingMessage" [position]="'position-right'"></cx-info-message>
          </a>
        </div>
      </ng-template>

    </div>
  </div>

</ng-container>
