import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthModule } from '@auth0/auth0-angular';
import { ICON_TYPE, IconConfig, IconResourceType } from '@spartacus/storefront';
import { AppComponent } from './app.component';
import { SpartacusModule } from './spartacus/spartacus.module';
import { CustomProductAddToCartModule } from './spartacus/features/cart/components/custom-product-add-to-cart/custom-product-add-to-cart.module';
import { CustomAddedToCartDialogModule } from './spartacus/features/cart/components/custom-added-to-cart-dialog/custom-added-to-cart-dialog.module';
import { SharedModule } from './shared/shared.module';
import { CustomProductIntroModule } from './spartacus/features/product/components/custom-product-intro/custom-product-intro.module';
import { CustomDownloadLinkModule } from './spartacus/features/product/components/custom-download-link/custom-download-link.module';
import { ProductListModule } from './spartacus/features/product/product-list';
import { ProductSummaryModule } from './spartacus/features/product/product-summary/product-summary.module';
import { CustomCheckoutPaymentTypeModule } from './spartacus/features/checkout/components/custom-checkout-payment-type/custom-checkout-payment-type.module';
import { SubscriptionsModule } from './pages/subscriptions/subscriptions.module';
import { environment } from 'src/environments/environment';
import { CustomCheckoutDeliveryAddressModule } from './spartacus/features/checkout/components/custom-checkout-delivery-address/custom-checkout-delivery-address.module';
import { CartProceedToCheckoutModule } from './spartacus/features/cart/components/cart-proceed-to-checkout/cart-proceed-to-checkout.module';
import { CheckoutReviewSubmitModule } from './spartacus/features/checkout/components/checkout-review-submit/checkout-review-submit.module';
import { CategoryNavigationModule } from './spartacus/features/category-navigation/category-navigation.module';
import { ProductCarouselModule } from './spartacus/features/product/components/product-carousel/product-carousel.module';
import { ProductReferencesModule } from './spartacus/features/product/components/product-references/product-references.module';
import { CustomBannerCarouselModule } from './spartacus/features/product/components/custom-banner-carousel/custom-banner-carousel.module';
import { ProductModule } from './spartacus/features/product/product.module';
import { TabParagraphContainerModule } from "./spartacus/features/content/tab-paragraph-container/tab-paragraph-container.module";
import { MiniCartModule } from './spartacus/features/cart/components/mini-cart/mini-cart.module';
import { CustomAvailabilityDateModule } from './spartacus/features/product/components/custom-availabity-date/custom-availability-date.module';
import { HomeModule } from "./pages/home/home.module";
import { ProductBundleListModule } from "./pages/product-bundle-list/product-bundle-list.module";
import {
  AuthHttpHeaderService,
  BaseSiteService,
  ConfigModule,
  OccEndpointsService,
} from "@spartacus/core";
import { AppRoutingModule } from "./spartacus/router/app-routing.module";
import { ProductSuggestionsModule } from './spartacus/features/product/components/product-suggestions/product-suggestions.module';
import { GeneracProductHeaderMenuModule } from './spartacus/features/product/components/product-header-menu/product-header-menu.module';
import { GeneracProductAccessoriesModule } from './spartacus/features/product/components/product-accessories/product-accessories.module';
import { NotificationResponseInterceptor } from './interceptors/notifications-response/notifications-response.interceptor';
import { CustomBaseSiteService } from './services/custom-base-site.service';
import { CustomOccEndpointsService } from "./services/custom-occ-endpoints.service";
import { CustomAuthHttpHeaderService } from "./spartacus/features/auth/services/auth-http-header.service";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    CustomProductAddToCartModule,
    CustomProductIntroModule,
    CustomDownloadLinkModule,
    CustomAvailabilityDateModule,
    CustomBannerCarouselModule,
    CustomAddedToCartDialogModule,
    CustomCheckoutPaymentTypeModule,
    CustomCheckoutDeliveryAddressModule,
    CategoryNavigationModule,
    CartProceedToCheckoutModule,
    ProductListModule,
    ProductCarouselModule,
    ProductReferencesModule,
    ProductSuggestionsModule,
    ProductSummaryModule,
    GeneracProductHeaderMenuModule,
    GeneracProductAccessoriesModule,
    CheckoutReviewSubmitModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    AuthModule.forRoot({
      domain: environment.domain,
      clientId: environment.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin + '/login',
        audience: 'api://sapcommerce.auth0',
        scope: 'openid profile email invoke:api',
      },
      cacheLocation: "localstorage"
    }),
    SpartacusModule,
    SubscriptionsModule,
    SharedModule,
    ProductModule,
    ProductBundleListModule,
    TabParagraphContainerModule,
    MiniCartModule,
    HomeModule,
    AppRoutingModule,
    ConfigModule.withConfig(<IconConfig>{
      icon: {
        symbols: {
          TRASH: "icon-trash",
          STAR: "icon-star",
          INFO: "icon-info",
          HEADSET: "icon-headset",
          PIN: "icon-pin",
        },
        resources: [
          {
            type: IconResourceType.SVG,
            url: "./assets/icons/trash.svg",
            types: [ICON_TYPE.TRASH],
          },
          {
            type: IconResourceType.SVG,
            url: "./assets/icons/star.svg",
            types: [ICON_TYPE.STAR],
          },
          {
            type: IconResourceType.SVG,
            url: "./assets/icons/info.svg",
            types: [ICON_TYPE.INFO],
          },
          {
            type: IconResourceType.SVG,
            url: "./assets/icons/headset.svg",
            types: [ICON_TYPE.HEADSET],
          },
          {
            type: IconResourceType.SVG,
            url: "./assets/icons/pin.svg",
            types: ['PIN'],
          }
        ],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotificationResponseInterceptor,
      multi: true
    },
    {
      provide: AuthHttpHeaderService,
      useClass: CustomAuthHttpHeaderService,
    },
    {
      provide: BaseSiteService,
      useClass: CustomBaseSiteService,
    },
    {
      provide: OccEndpointsService,
      useClass: CustomOccEndpointsService,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
