<cx-address-details
    *ngIf="isFormInitialized else loading"
    [formGroup]="formGroup"
    [subscriptionProducts]="subscriptionProducts"
    [internalCalculation]="internalCalculation"
    [isCartMixed]="isCartMixed"
    [currentStepId]="currentStep.id"
    [checkoutInfo]="checkoutInfo"
    [singleConsignmentInfo]="singleConsignmentInfo"
    [subscriptionInfo]="subscriptionInfo"
    (loaded)="addressLoaded()"
></cx-address-details>

<ng-template #loading>
    <div class="spinner-overlay">
        <cx-spinner></cx-spinner>
    </div>
</ng-template>

<ng-container *ngIf="(currentStep.id == 'subscriptionInfo' && isSubscription) || currentStep.id == 'deliveryAddress'">
    <div
            cxAppendAnchor="deliveryAddress"
            [anchorOffset]="false"
            class="d-flex pb-2 pt-2 delivery-address-block"
    >
        <h2 class="d-none d-lg-block">
            {{ 'checkoutAddress.shippingAddress' | cxTranslate }}
        </h2>
    </div>

    <div class="delivery-info">
        <cx-icon class="regular-icon grey not-active" [type]="iconTypes.INFO"></cx-icon>
        <span class="info-desc">
            Final Destination cannot be that of a freight terminal. All Broker, Freight Forwarder charges, Duties and Taxes
            to be paid by purchaser.
        </span>
    </div>

    <div class="pb-2 pt-2">Address</div>
    <ng-select
            [items]="cards$ | async"
            placeholder="Select address"
            [class.text-class]="selectedCard?.address?.addressType?.code === AddressType.DROP_SHIP"
            [clearable]="false"
            [searchable]="true"
            [(ngModel)]="selectedCard"
            [searchFn]="addressSearchFn"
            (ngModelChange)="verifiedAddress = null;fFAddresses=[];fFAddressFormValue=null;"
            (change)="onChangeCurrentAddress($event)"
    >
        <ng-template ng-label-tmp let-item="item">
            <div class="bold-title">
                <strong>{{ item.address.addressType.name }}</strong>
                <ng-container *ngIf="item.address.addressType.code !== AddressType.DROP_SHIP">:
                    <strong> {{ item.address.companyName }}</strong>
                </ng-container>
                <ng-container *ngIf="item.address.addressType.code === AddressType.DROP_SHIP && dropShipForm?.addressForm?.value.residential">
                    <div class="delivery-residential">
                        <cx-icon class="favorite-icon small active" [type]="iconTypes.PIN"></cx-icon>
                        <div>Residential Address</div>
                    </div>
                </ng-container>
            </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="address">
                <div class="address-option">
                    <div class="bold-title">
                        <strong>{{ item.address.addressType.name }}</strong>
                        <ng-container *ngIf="item.address.addressType.code !== AddressType.DROP_SHIP">:
                            <strong> {{ item.address.companyName }}</strong>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="item.address.addressType.code !== AddressType.DROP_SHIP">
                        <span>{{ item.address.formattedAddress }}</span><span>{{ item.address.cellphone }}</span>
                    </ng-container>
                </div>
                <div *ngIf="item.card.header" class="selected-block">
                    <img src="../../../../../../assets/icons/done_orange_24.svg" />
                    <span>Selected</span>
                </div>
            </div>
        </ng-template>
    </ng-select>

    <generac-address-form
            *ngIf="selectedCard && selectedCard.address.addressType.code !== AddressType.DROP_SHIP"
            [generacAddressData]="selectedCard.address"
            [setAsDefaultField]="false"
            [disableForm]="true"
            [fromCheckout]="true"
            [showTitleCode]="false"
            [showSubmitBtn]="false"
            [showCancelBtn]="false"
            [addressType]="selectedCard.address.addressType.code"
    ></generac-address-form>

    <generac-address-form
            *ngIf="selectedCard && selectedCard.address.addressType.code === AddressType.DROP_SHIP"
            #dropShipForm
            [generacAddressData]="verifiedAddress"
            [setAsDefaultField]="false"
            [fromCheckout]="true"
            [disableForm]="false"
            [showTitleCode]="false"
            [actionBtnLabel]="'Ship Here'"
            [showSubmitBtn]="!verifiedAddress"
            [showCancelBtn]="false"
            (submitAddress)="onVerify($event)"
            [isEditable]="true"
            [addressType]="selectedCard.address.addressType.code"
            (saveDropShipAddressChanged)="saveDropShipAddressChanged($event)"
            (limitCountOfAddressesMessage)="limitCountOfAddressesMessageChange($event)"
    ></generac-address-form>

    <cx-custom-message
            [messages]="floorPlanErrorMessage"
            [isPermanent]="true"
            [isCornered]="true"
            [isIconVisible]="true"
    ></cx-custom-message>

    <ng-container *ngIf="fFAddresses?.length">
        <app-freight-forword-form
                #ffAddress
                [selectedFFAddress]="freightForwarderAddress"
                [fFAddresses]="fFAddresses"
                [selectedCountry]="selectedCard?.address?.country?.isocode"
                (fFAddressesChange)="getFFCustomeAddress($event)"
        ></app-freight-forword-form>
    </ng-container>


    <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>
    <ng-container *ngIf="!subscriptionProducts?.length">
        <div class="form-check-title">{{ singleConsignmentInfo?.message  }}</div>
        <label class="one-line-item form-check">
            <span class="form-check-label">{{ singleConsignmentInfo?.title }}</span>
            <input type="checkbox" role="checkbox" [class.desired-disable]="singleConsignmentInfo?.disabled && singleConsignmentInfo?.state" class="checkout-checkbox" [formControl]="shipOnceCheckbox">
        </label>
        <div class="delivery-info" *ngIf="singleConsignmentInfo?.messageNote">
            <cx-icon class="regular-icon grey not-active" [type]="iconTypes.INFO"></cx-icon>
            <span class="info-desc">{{ singleConsignmentInfo?.messageNote }}</span>
        </div>
    </ng-container>
</ng-container>

<div class="cx-checkout-btns row">
    <div class="col-md-6 col-lg-6">
        <button *ngIf="!cart?.powerPlayOrder && !isSubscription" class="cx-btn btn btn-block btn-secondary" (click)="back()">
            {{ backBtnText | cxTranslate }}
        </button>
        <button *ngIf="cart?.powerPlayOrder || isSubscription" class="cx-btn btn btn-block btn-secondary" (click)="backToSubs()">
            Back to Subscriptions
        </button>
    </div>
    <div class="col-md-6 col-lg-6">
        <button class="cx-btn btn btn-block btn-primary"
            [disabled]="(!(verifiedAddress || cart?.deliveryAddress && selectedCard?.address?.addressType?.code !== AddressType.DROP_SHIP) || formGroup.status === 'INVALID' || (continueDisabled$ | async) || ffAddress?.addressForm?.invalid) || floorPlanErrorMessage?.length"
            (click)="next()">
            {{ 'common.continue' | cxTranslate }}
        </button>
    </div>
</div>
