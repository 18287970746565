import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CartItemComponentOptions } from '@spartacus/cart/base/root';
import { CmsAddToCartComponent, EventService, GlobalMessageService, GlobalMessageType, TranslationService, WindowRef, isNotNullable } from '@spartacus/core';
import { CmsComponentData, CurrentProductService, ICON_TYPE, ProductListItemContext } from '@spartacus/storefront';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Badge, ProductExtended } from "../../../../../interfaces/product";
import { BaseStoreService } from 'src/app/services/base-store.service';
import { ProductService } from 'src/app/services/product.service';
import { BundleService } from "../../../../../services/bundle.service";
import { AddToBundleResponse } from "../../../../../interfaces/bundle.model";
import { CartUiEventAddToCart } from '../custom-added-to-cart-dialog/custom-added-to-cart-dialog-event.listener';
import { GaListNames } from 'src/app/enums/ga-list-names.enum';
import { ActivatedRoute } from '@angular/router';
import { GeneracActiveCartService } from '../../core/facade/active-cart.service';

@Component({
  selector: 'cx-add-to-cart',
  templateUrl: './custom-product-add-to-cart.component.html',
  styleUrls: ['./custom-product-add-to-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomProductAddToCartComponent implements OnInit, OnDestroy {
  @Input() productCode!: string;
  @Input() showQuantity = true;
  @Input() options!: CartItemComponentOptions;
  /**
   * As long as we do not support #5026, we require product input, as we need
   *  a reference to the product model to fetch the stock data.
   */
  @Input() product!: ProductExtended;
  @Input() purchasable: boolean;
  @Input() bundleId: string;
  @Input() isSavedCart: boolean;
  @Input() gaListName: string;
  @Input() gaProductCategories: { name: string; }[];
  @Input() isIcon: boolean;
  @Input() isMobileBtn: boolean;
  @Input() isSubs: boolean;
  @Input() isAddModal: boolean;
  @Output() onBundleAdded = new EventEmitter<AddToBundleResponse>();
  @Output() onAddModalAdded = new EventEmitter<{productCode: string, quantity: string}>();

  minQuantity!: number;
  maxQuantity!: number;
  addToCartQtyMultiplier!: number;
  stockBadge: Badge;
  marketingBadge: Badge;
  contactForPricingHref: string;
  showInventory$: Observable<boolean | undefined> | undefined =
    this.component?.data$.pipe(map((data) => data.inventoryDisplay));
  quantity = 1;
  callForPricingMessage = '';
  phoneOrEmail: string;
  tooltipMessage: string;
  addToCartForm = new UntypedFormGroup({
    quantity: new UntypedFormControl(1, {updateOn: 'change'}) as UntypedFormControl,
  });
  quantityControl = this.addToCartForm.get('quantity') as UntypedFormControl;
  iconTypes = ICON_TYPE;
  navigatedToPpdFrom: string;
  ltlMessage: string = '';
  tooltipLtlMessage$: Observable<string>;
  flammableMessage: string = '';
  tooltipFlammableMessage$: Observable<string>;
  private subscription = new Subscription();

  constructor(
    protected currentProductService: CurrentProductService,
    protected cd: ChangeDetectorRef,
    protected activeCartService: GeneracActiveCartService,
    private productService: ProductService,
    private globalMessageService: GlobalMessageService,
    @Optional() public component: CmsComponentData<CmsAddToCartComponent>,
    protected eventService: EventService,
    protected winRef: WindowRef,
    protected activatedRoute: ActivatedRoute,
    protected translation: TranslationService,
    private baseStoreService: BaseStoreService,
    private bundleService: BundleService,
    @Optional() protected productListItemContext?: ProductListItemContext,
  ) {
    this.getPhoneNumber();
  }

  ngOnInit() {
    this.setProduct();   
    this.tooltipLtlMessage$ = this.translation.translate('productList.tooltipLtlMessage');
    this.tooltipFlammableMessage$ = this.translation.translate('productList.tooltipFlammableMessage');
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  /**
   * In specific scenarios, we need to omit displaying the stock level or append a plus to the value.
   * When backoffice forces a product to be in stock, omit showing the stock level.
   * When product stock level is limited by a threshold value, append '+' at the end.
   * When out of stock, display no numerical value.
   */
  getInventory(): string {
    return this.productCode && this.maxQuantity ? this.maxQuantity.toString() : '';
  }

  updateCount(value: number): void {
    this.quantity = value;
  }

  addToCart() {
    if (this.product.excludedByRegion) {
      this.globalMessageService.add(
        `This material is not currently available to you based on regional restrictions. Please contact your Sales Representative for more information via ${this.phoneOrEmail}`,
        GlobalMessageType.MSG_TYPE_ERROR
      );
      return;
    }
    const quantity = this.addToCartForm.get('quantity')?.value;
    if (!this.productCode || quantity <= 0) {
      return;
    }

    if (this.productCode === this.productService.productCodeWithoutCPI) {
      this.globalMessageService.add(
        {
          key: 'httpHandlers.domainError',
          params: { phoneNumber: this.phoneOrEmail }
        },
        GlobalMessageType.MSG_TYPE_ERROR
      );

      return;
    }

    if (this.activatedRoute.snapshot.queryParams?.gaListName) {
      this.navigatedToPpdFrom = this.activatedRoute.snapshot.queryParams?.gaListName;
    }

    if (this.bundleId) {
      this.bundleService.addToCart(this.bundleId, this.productCode)
        .subscribe(response => this.onBundleAdded.emit(response));
      return;
    }

    if(this.isAddModal) {
      this.onAddModalAdded.emit({
        productCode: this.productCode,
        quantity
      });
      return;
    }

    this.activeCartService
      .getEntries()
      .pipe(
        take(1)
      ).subscribe((cartEntries) => {
        this.activeCartService.addEntry(this.productCode, quantity, this.gaListName, this.navigatedToPpdFrom);

        // A CartUiEventAddToCart is dispatched.  This event is intended for the UI
        // responsible to provide feedback about what was added to the cart, like
        // the added to cart dialog.
        //
        // Because we call activeCartService.getEntries() before, we can be sure the
        // cart library is loaded already and that the event listener exists.
        this.eventService.dispatch(this.createCartUiEventAddToCart(this.productCode, quantity, cartEntries.length, this.gaListName));
      });
  }

  protected setStockInfo(product: ProductExtended): void {
    this.quantity = 1;
    this.stockBadge = product?.badges?.length && product?.badges?.find(badge => !badge.marketing)

    if (this.productListItemContext) {
      this.showQuantity = false;
    }
  }

  protected setMarketingBadge(productBadges: Badge[]) {
    this.marketingBadge = productBadges?.length && productBadges?.find(badge => !!badge.marketing);
  }

  protected createCartUiEventAddToCart(productCode: string, quantity: number, numberOfEntriesBeforeAdd: number, gaListName: string) {
    const newEvent = new CartUiEventAddToCart();
    newEvent.productCode = productCode;
    newEvent.quantity = quantity;
    newEvent.numberOfEntriesBeforeAdd = numberOfEntriesBeforeAdd;
    newEvent.gaListName = gaListName;
    return newEvent;
  }

  private getPhoneNumber() {
    this.subscription.add(
      combineLatest([
        this.baseStoreService.getTooltipMessage(),
        this.baseStoreService.getPhoneNumber(),
      ]).subscribe(([messsage, phoneOrEmail]) => {
        this.contactForPricingHref = phoneOrEmail?.contactInfo?.includes('@') ? `mailto: ${phoneOrEmail}` : `tel: ${phoneOrEmail}`;
        this.tooltipMessage = messsage?.message;
        this.phoneOrEmail = phoneOrEmail?.contactInfo;
      })
    );
  }

  private setProduct() {
    if (this.product) {
      this.productCode = this.product.code ?? '';
      this.purchasable = this.product.purchasable;
      this.setStockInfo(this.product);
      this.setProductData(this.product);
      this.cd.markForCheck();
      return;
    }

    if (this.productCode) {
      this.quantity = 1;
      this.cd.markForCheck();
      return;
    }

    this.subscribeToProduct();
  }

  private setProductData(product: ProductExtended) {
    this.product = product;
    this.purchasable = product.purchasable;
    this.productCode = product.code ?? '';
    this.minQuantity = product.minOrderQuantity !== undefined && product.minOrderQuantity !== null ? Number(product.minOrderQuantity) : 1;
    this.quantity = this.minQuantity;
    this.maxQuantity = product.maxOrderQuantity !== undefined && product.maxOrderQuantity !== null ? Number(product.maxOrderQuantity) : Infinity;
    this.addToCartQtyMultiplier = Number(product.addToCartQtyMultiplier) ?? 1;
    this.setStockInfo(product);
    this.setMarketingBadge(this.product.badges);
    if(this.product?.code && !this.gaListName && this.winRef.location.pathname.includes(this.product?.code)) {
      this.gaListName = GaListNames.PDP;
    }
    this.cd.markForCheck();
  }

  private subscribeToProduct() {
    const product$ = this.productListItemContext ?
      this.productListItemContext.product$ :
      this.currentProductService.getProduct();
    this.subscription.add(
      product$
        .pipe(filter(isNotNullable))
        .subscribe((product: ProductExtended) => {
          this.setProductData(product);
        })
    );
  }
}
